<template>
  <v-switch
    :label="$t('form.autosave')"
    v-model="autoSaveForm"
    @change="updateState"
  />
</template>
<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";

@Component
export default class SwitchAutoSave extends Vue {
  @VModel({ type: Boolean, default: false }) autoSaveForm!: boolean;

  mounted() {
    const sAutoSave = localStorage.getItem("global.autosave");
    if (sAutoSave) {
      const bAutoSave = sAutoSave === "true";
      this.$emit("input", bAutoSave);
    }
  }

  updateState(bValue: boolean) {
    localStorage.setItem("global.autosave", this.$_.toString(bValue));
  }
}
</script>
